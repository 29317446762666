import Error from "next/error";

import type {
  FeatureLayoutComponent,
  FeaturePageComponent
} from "@pwa/features/page";
import { withPage } from "@pwa/features/page";
import { RootLayout } from "@pwa/features/root";

const Page404: FeaturePageComponent = () => {
  return <Error statusCode={404} />;
};

const Page404Layout: FeatureLayoutComponent = ({ children }) => <>{children}</>;
Page404Layout.getLayout = (page, props) =>
  RootLayout.getLayout(<Page404Layout>{page}</Page404Layout>, props);

Page404.PageLayout = Page404Layout;
Page404.displayLoader = false;

export default withPage(Page404);
